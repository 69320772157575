import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
// import HeatlhRisk from './pages/header/HealthRiskTests';
import ContactHeader from './components/contactHeader';
import HealthCondition from './pages/header/heatlhCondition';
import HomeVisit from './pages/header/homeVisit';
import Upload from './pages/header/upload';
import Footer from './components/footer';

// Other Routes
import UserProfile from './pages/SignIn/UserProfile';
import UserSignInForm from './pages/SignIn/UserSignInForm';
import Specialty from './pages/header/Specialty';
import ManageAddress from './pages/SignIn/ManageAddress';
import Test from "../src/pages/Test"
import BottomNavbar from './components/BottomNavbar';
import { CartProvider } from './context/CartContext';
import FinalPayment from './pages/SignIn/FinalPayment';

//policy routes
import PrivacyPolicy from './policypages/PrivacyPolicy';
import RefundPolicy from './policypages/RefundPolicy';
import TermsAndConditions from './policypages/TermsAndConditions';
import AddressList from './pages/SignIn/AddressList';
import HealthConditionCollection from './pages/healthCondition/HealthConditionCollection';
import HealthPackage from './HomePageContent/HealthPackage';
import HealthRiskCollection from './pages/healthRisks/HealthRiskCollection';
import HealthPackageDetail from './pages/HealthPackage/HealthPackageDetail';

// import PopularTestDetail from './pages/PopularTest/PopularTestDetail';
import PopularTestCollection from './pages/PopularTest/PopularTestCollection';
import HealthRiskNavbarLink from './pages/header/HealthRiskNavbarLink';
import HealthPackageCollection from './pages/HealthPackage/HealthPackageCollection';
import BloodTest from './pages/header/BloodTest';

function Layout() {
  return (
    <>
      <div className="relative">
        <ContactHeader />
        <Header />
        <div className="min-h-screen">
          <Outlet />
        </div>
        <BottomNavbar />
        <Footer />
      </div>
    </>
  );
}

const App = () => {
  return (
    <CartProvider>
      <div>
        <Routes>
          <Route element={<Layout />}>

            <Route path="/" element={<Home />} />
            {/* <Route path="/heatlhRisk" element={<HeatlhRisk />} /> */}
            <Route path="/health-packages" element={<HealthPackage />} />
            <Route path="/health-conditions" element={<HealthCondition />} />
            <Route path="/home-visit" element={<HomeVisit />} />
            <Route path="/upload-report" element={<Upload />} />
            <Route path="/specialty" element={<Specialty />} />

            {/* HealthRisk routes */}

            <Route path="/healthrisknavbarlink" element={<HealthRiskNavbarLink />} />
            <Route path="/:category" element={<HealthRiskCollection />} />

            <Route path="/:category/:id" element={<HealthPackageDetail />} />

            <Route path="/bloodTest" element={<BloodTest />} />


            <Route path="/populartestcollection" element={<PopularTestCollection />} />
            <Route path="/healthpackagecollection" element={<HealthPackageCollection />} />


            <Route path="/userprofile" element={<UserProfile />} />
            <Route path="/userform" element={<UserSignInForm />} />
            <Route path="/manageaddress" element={<ManageAddress />} />
            <Route path="/finalPayment" element={<FinalPayment />} />
            <Route path="/addressmodal" element={<AddressList />} />

            <Route path="/test" element={<Test />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/termsandconditions" element={<TermsAndConditions />} />

            <Route path="/test/:name" element={<HealthConditionCollection />} />


          </Route>
        </Routes>
      </div>
    </CartProvider>
  );
};

export default App;
