import React, { useEffect, useState } from "react";
import axios from "axios";
import constantApi from "../../constantApi";
import { useCart } from "../../context/CartContext";
import testube2 from "../../Images/testube2.png";
import { useNavigate } from "react-router-dom";

const BloodTest = () => {
    const [tests, setTests] = useState([]);
    const { addToCart, cartItemIds } = useCart();
    const navigate = useNavigate()

    useEffect(() => {
        axios
            .get(`${constantApi.baseUrl}user/product/${"Blood Test"}`)
            .then((response) => {
                console.log("response is", response);
                setTests(response.data.data);
            })
            .catch((error) => {
                console.log("error is", error);
            });
    }, []);

    const handleViewDetails = (test) => {
        navigate(`/Blood Test/${test._id}`, { state: { test } });
    };

    return (
        <div className="pb-12 mt-8">
            <section>

                <div className="flex justify-between items-center my-6 mx-8">
                    <div className='bg-gray-100 p-4 border-2 border-gray-100 w-full rounded-lg'>
                        <h2 className="lg:text-3xl md:text-xl sm:text-lg text-lg font-bold mb-2 text-gray-800">Blood Tests We Have</h2>
                        <p className="lg:text-lg md:text-md sm:text-sm text-sm font-semibold text-gray-800">              Explore Our Comprehensive Range of Tests
                        </p>
                    </div>
                </div>

                {/* Cards for each test */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mx-8">
                    {tests.map((test) => (
                        <div
                            key={test.id}
                            className="flex flex-col border border-gray-300 shadow-lg rounded-lg px-4 py-4"
                        >
                            <div
                                onClick={() => handleViewDetails(test)}
                                className="mb-4 sm:mb-8">
                                <img
                                    src={testube2}
                                    alt={test.name}
                                    className="w-16 h-16 sm:w-20 sm:h-20 mx-auto"
                                />
                                <h3 className="text-sm sm:text-lg font-semibold text-gray-700 mt-2">
                                    {test.name}
                                </h3>
                            </div>

                            <div className="flex justify-between mt-4">
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        addToCart(test);
                                    }}
                                    className={`text-white py-1 px-4 w-4/5 h-10 text-sm rounded-md ${cartItemIds.includes(test._id) ? "bg-green-600" : "bg-[#522f7c]"
                                        }`}
                                >
                                    {cartItemIds.includes(test._id) ? "Added to Cart" : "Add to Cart"}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default BloodTest;
