import React from "react";
import { useNavigate } from "react-router-dom";

function HealthCondition() {
  const navigate = useNavigate();

  const tests = [
    { id: 1, name: "Blood Alchohal Test", img: "", testCount: 14, route: "/Blood-alchohal-test" },
    { id: 2, name: "Allergy", img: "", testCount: 10, route: "/Allergy-test" },
    { id: 3, name: "Anemia", img: "", testCount: 8, route: "/Anemia-test" },
    { id: 4, name: "Arthritis", img: "", testCount: 10, route: "/Arthritis-test" },
    { id: 5, name: "Cancer", img: "", testCount: 13, route: "/Cancer-test" },
    { id: 6, name: "Diabetes", img: "", testCount: 13, route: "/Diabetes-test" },
    { id: 7, name: "Fever", img: "", testCount: 13, route: "/Fever-test" },
    { id: 8, name: "Hepatitis", img: "", testCount: 13, route: "/Hepatitis-test" },
    { id: 9, name: "Hormones", img: "", testCount: 13, route: "/Hormones-test" },
    { id: 10, name: "Hypertension", img: "", testCount: 13, route: "/Hypertension-test" },
    { id: 11, name: "Obesity", img: "", testCount: 13, route: "/Obesity-test" },
    { id: 12, name: "Pregnancy", img: "", testCount: 13, route: "/Pregnancy-test" },
    { id: 13, name: "STD (Sexually Transmitted Diseases)", img: "", testCount: 13, route: "/STD-test" },
    { id: 14, name: "Vitamins", img: "", testCount: 13, route: "/Vitamins-test" },
  ];

  const handleClick = (testName) => {
    navigate(`/test/${testName}`); // Navigate to the dynamic test route
  };

  return (
    <div className="px-4 sm:px-8 lg:px-16 py-8 sm:py-12 bg-gray-100">
      <div className="h-auto bg-white px-4 py-3 rounded-xl shadow-sm">
        <p className="text-xl sm:text-2xl font-semibold text-gray-700">Health Condition</p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
        {tests.map((test) => (
          <div
            key={test.id}
            className="p-4 bg-white shadow-md rounded-lg flex items-center cursor-pointer transition-all duration-200 hover:shadow-lg"
            onClick={() => handleClick(test.name)}
          >
            <div className="h-24 w-24 flex justify-center items-center border border-sky-500 rounded-lg sm:h-28 sm:w-28">
              <span role="img" aria-label="icon" className="text-lg sm:text-2xl">
                {test.img || "🌟"}
              </span>
            </div>
            <div className="ml-4">
              <p className="text-sm sm:text-lg font-semibold text-gray-800">{test.name}</p>
              <p className="text-gray-500 text-xs sm:text-sm">Tests: {test.testCount}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HealthCondition;
