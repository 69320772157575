import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useCart } from "../../context/CartContext";
import FAQ from "../../components/FAQ";

const HealthPackageDetail = () => {
    const [packages, setPackages] = useState([]);
    const location = useLocation();
    const { test } = location.state;
    const { addToCart, cartItemIds } = useCart();

    useEffect(() => {
        setPackages(test.package);
    }, [test]);

    const [openPackages, setOpenPackages] = useState({});

    // Toggle function to open or close a package
    const togglePackage = (category) => {
        setOpenPackages((prevState) => ({
            ...prevState,
            [category]: !prevState[category],
        }));
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="px-4 py-4 flex flex-wrap justify-between gap-4">
            {/* Right Section */}
            <div className="w-full md:w-[25%] order-1 md:order-2 lg:mt-4 md:mt-8 sm:mt-0 mt-0">
                <div className={`border-2  ${cartItemIds.includes(test._id) ? "border-green-600" : "border-[#522f7c]"} p-4 md:p-8 rounded-t-lg`}>
                    <h2 className="text-2xl md:text-3xl font-bold text-gray-800 text-center">{test.name}</h2>
                    <p className="text-lg md:text-lg text-gray-600 mt-2 text-center">Rs. {test.amount}</p>
                </div>
                <div>
                    <button
                        onClick={() => addToCart(test)}
                        className={`text-white py-2 lg:text-xl md:text-lg sm:text-md text-md px-4 w-full font-bold   ${cartItemIds.includes(test._id)
                            ? 'bg-green-600'
                            : 'bg-[#522f7c]'
                            }`}
                    >
                        {cartItemIds.includes(test._id)
                            ? 'Added to Cart'
                            : 'Add to Cart'}
                    </button>
                </div>
            </div>

            {/* Left Section */}
            <div className="w-full md:w-[70%] order-2 md:order-1">
                <div className="bg-white border-2 border-gray shadow-lg mt-4 p-4">
                    <h2 className="text-2xl md:text-3xl font-bold text-gray-800 text-center">{test.name}</h2>
                    <p className="text-sm md:text-lg text-gray-600 mt-2">Sample Type - Blood</p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 mt-4 gap-4">
                    <div className="bg-white border-2 border-gray shadow-lg p-4">
                        <p className="text-sm md:text-base">Smart Report With Trends Analysis</p>
                    </div>
                    <div className="bg-white border-2 border-gray shadow-lg p-4">
                        <p className="text-sm md:text-base">NABL & CAP Accredited Labs</p>
                    </div>
                    <div className="bg-white border-2 border-gray shadow-lg p-4">
                        <p className="text-sm md:text-base">Accurate and Quality Test Reports</p>
                    </div>
                </div>
                <div className="bg-white border-2 border-gray shadow-lg mt-4 p-4">
                    <h1 className="text-lg md:text-xl font-semibold">Package Overview</h1>
                    <p className="mt-4 text-sm md:text-base">{test.description}</p>
                </div>
                <div>

                    {packages && packages.length > 0 ? (
                        <>
                            {/* Included Packages Section */}
                            <h3 className="text-lg md:text-xl font-semibold mt-6">Included Packages:</h3>
                            <div className="mt-4">
                                {packages.map((pack) => (
                                    <div
                                        key={pack.category}
                                        className="mt-2 border-2 border-gray-50 shadow-xl bg-gray-300 rounded-lg"
                                    >
                                        {/* Package Title */}
                                        <h1
                                            className="p-2 cursor-pointer text-purple-900 font-bold"
                                            onClick={() => togglePackage(pack.category)}
                                        >
                                            {pack.category}
                                        </h1>

                                        {/* Items Dropdown with Smooth Transition */}
                                        <div
                                            className={`overflow-hidden transition-all duration-500 ease-in-out ${openPackages[pack.category] ? "max-h-screen" : "max-h-0"
                                                }`}
                                        >
                                            {pack.data.map((item) => (
                                                <div
                                                    key={item.name}
                                                    className="pl-4 py-2 hover:underline text-sm md:text-base text-blue-600"
                                                >
                                                    {item.name}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        /* Fallback Message if Packages are Not Found */
                        <div className="mt-6 text-red-600 text-lg">

                        </div>
                    )}

                    {/* FAQ Section */}
                    <div className="mt-8">
                        <FAQ />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default HealthPackageDetail;
