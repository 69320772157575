import axios from 'axios';
import { useEffect, useState } from 'react';
import { useUser } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import constantApi from '../../constantApi';
import Cookies from 'js-cookie'; // Import js-cookie library

const SignInButton = () => {
    // const { setUserDetail } = useUser();
    const navigate = useNavigate();
    const [apiCalled, setApiCalled] = useState(false);

    useEffect(() => {
        if (apiCalled) return;
        const script = document.createElement('script');
        script.src = 'https://www.phone.email/sign_in_button_v1.js';
        script.async = true;
        script.onload = () => {
            window.phoneEmailListener = function (userObj) {
                if (!apiCalled) {
                    setApiCalled(true);
                    const user_json_url = userObj.user_json_url;
                    if (user_json_url) {
                        axios
                            .post(`${constantApi.baseUrl}user/login`, {
                                countrycode: userObj.user_country_code,
                                phone: userObj.user_phone_number,
                                url: user_json_url
                            })
                            .then((response) => {
                                console.log("response is ", response);
                                localStorage.setItem('token', response.data.data.token);
                                Cookies.set('token', response.data.data.token, { expires: 7, path: '' }); // Expires in 7 days

                                if (response.data.data.isnewuser === false) {
                                    navigate("/userprofile");
                                    // navigate("/");
                                } else {
                                    navigate("/userform");
                                }
                            })
                            .catch((error) => {
                                console.log('Error during login API:', error);
                            });
                    }
                }
            };
        };
        const signinButtonDiv = document.querySelector('.pe_signin_button');
        if (signinButtonDiv) {
            signinButtonDiv.appendChild(script);
        }
        return () => {
            const signinButtonDiv = document.querySelector('.pe_signin_button');
            if (signinButtonDiv && signinButtonDiv.contains(script)) {
                signinButtonDiv.removeChild(script);
            }
        };
    }, [navigate, apiCalled]);


    return (
        <>

            <div className="pe_signin_button" data-client-id="14161999225459584218"></div>
        </>
    )
};

export default SignInButton;
