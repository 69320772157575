import React from 'react'
import doctor from "../../Images/home_img/doctor.png"
const Specialty = () => {
    return (
        <div>
            <div className=" my-4 flex justify-center items-center">
                <div className="flex justify-center items-center bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl">
                    <div className="flex items-center justify-center mr-6">
                        <img
                            src={doctor}
                            alt="Image of doctor"
                            className="w-40 h-40 object-cover rounded-full" // Adjust the size of the image
                        />
                    </div>
                    <div className="flex flex-col gap-4 w-full">
                        <div>
                            <h1 className="text-xl font-semibold">ABC</h1>
                            <p className="text-sm">Consultant Pathologist, Global Reference Lab</p>
                            <p className="text-sm">MD Pathology</p>
                        </div>
                        <hr className="my-4 border-t-2 border-gray-300" />
                        <div>
                            <h2 className="text-xl font-semibold">Department: Chemistry</h2>
                            <p className="text-sm">Expertise</p>
                            <p className="text-sm">Specialised in Chemistry, Transplant Immunology (HLA), Neurochemistry, Haematology</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className=" my-4 flex justify-center items-center">
                <div className="flex justify-center items-center bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl">
                    <div className="flex items-center justify-center mr-6">
                        <img
                            src={doctor}
                            alt="Image of doctor"
                            className="w-40 h-40 object-cover rounded-full" // Adjust the size of the image
                        />
                    </div>
                    <div className="flex flex-col gap-4 w-full">
                        <div>
                            <h1 className="text-xl font-semibold">ABC</h1>
                            <p className="text-sm">Consultant Pathologist, Global Reference Lab</p>
                            <p className="text-sm">MD Pathology</p>
                        </div>
                        <hr className="my-4 border-t-2 border-gray-300" />
                        <div>
                            <h2 className="text-xl font-semibold">Department: Chemistry</h2>
                            <p className="text-sm">Expertise</p>
                            <p className="text-sm">Specialised in Chemistry, Transplant Immunology (HLA), Neurochemistry, Haematology</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className=" my-4 flex justify-center items-center">
                <div className="flex justify-center items-center bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl">
                    <div className="flex items-center justify-center mr-6">
                        <img
                            src={doctor}
                            alt="Image of doctor"
                            className="w-40 h-40 object-cover rounded-full" // Adjust the size of the image
                        />
                    </div>
                    <div className="flex flex-col gap-4 w-full">
                        <div>
                            <h1 className="text-xl font-semibold">ABC</h1>
                            <p className="text-sm">Consultant Pathologist, Global Reference Lab</p>
                            <p className="text-sm">MD Pathology</p>
                        </div>
                        <hr className="my-4 border-t-2 border-gray-300" />
                        <div>
                            <h2 className="text-xl font-semibold">Department: Chemistry</h2>
                            <p className="text-sm">Expertise</p>
                            <p className="text-sm">Specialised in Chemistry, Transplant Immunology (HLA), Neurochemistry, Haematology</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className=" my-4 flex justify-center items-center">
                <div className="flex justify-center items-center bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl">
                    <div className="flex items-center justify-center mr-6">
                        <img
                            src={doctor}
                            alt="Image of doctor"
                            className="w-40 h-40 object-cover rounded-full" // Adjust the size of the image
                        />
                    </div>
                    <div className="flex flex-col gap-4 w-full">
                        <div>
                            <h1 className="text-xl font-semibold">ABC</h1>
                            <p className="text-sm">Consultant Pathologist, Global Reference Lab</p>
                            <p className="text-sm">MD Pathology</p>
                        </div>
                        <hr className="my-4 border-t-2 border-gray-300" />
                        <div>
                            <h2 className="text-xl font-semibold">Department: Chemistry</h2>
                            <p className="text-sm">Expertise</p>
                            <p className="text-sm">Specialised in Chemistry, Transplant Immunology (HLA), Neurochemistry, Haematology</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className=" my-4 flex justify-center items-center">
                <div className="flex justify-center items-center bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl">
                    <div className="flex items-center justify-center mr-6">
                        <img
                            src={doctor}
                            alt="Image of doctor"
                            className="w-40 h-40 object-cover rounded-full" // Adjust the size of the image
                        />
                    </div>
                    <div className="flex flex-col gap-4 w-full">
                        <div>
                            <h1 className="text-xl font-semibold">ABC</h1>
                            <p className="text-sm">Consultant Pathologist, Global Reference Lab</p>
                            <p className="text-sm">MD Pathology</p>
                        </div>
                        <hr className="my-4 border-t-2 border-gray-300" />
                        <div>
                            <h2 className="text-xl font-semibold">Department: Chemistry</h2>
                            <p className="text-sm">Expertise</p>
                            <p className="text-sm">Specialised in Chemistry, Transplant Immunology (HLA), Neurochemistry, Haematology</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" my-4 flex justify-center items-center">
                <div className="flex justify-center items-center bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl">
                    <div className="flex items-center justify-center mr-6">
                        <img
                            src={doctor}
                            alt="Image of doctor"
                            className="w-40 h-40 object-cover rounded-full" // Adjust the size of the image
                        />
                    </div>
                    <div className="flex flex-col gap-4 w-full">
                        <div>
                            <h1 className="text-xl font-semibold">ABC</h1>
                            <p className="text-sm">Consultant Pathologist, Global Reference Lab</p>
                            <p className="text-sm">MD Pathology</p>
                        </div>
                        <hr className="my-4 border-t-2 border-gray-300" />
                        <div>
                            <h2 className="text-xl font-semibold">Department: Chemistry</h2>
                            <p className="text-sm">Expertise</p>
                            <p className="text-sm">Specialised in Chemistry, Transplant Immunology (HLA), Neurochemistry, Haematology</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Specialty
