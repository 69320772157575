import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../context/CartContext";
import constantApi from "../../constantApi";


const PopularTestCollection = () => {
    const [tests, setTests] = useState([]);
    const [cardsPerPage, setCardsPerPage] = useState(1); // Track how many cards to show at a time
    const navigate = useNavigate();
    const { addToCart, cartItemIds } = useCart();

    // Fetch the tests data
    useEffect(() => {
        axios
            .get(`${constantApi.baseUrl}user/product/Popular Test`)
            .then((response) => {
                setTests(response.data.data);
            })
            .catch((error) => {
                console.error("Error fetching tests: ", error);
            });
    }, []);

    // Adjust number of cards to show based on screen size
    const updateCardsPerPage = () => {
        if (window.innerWidth >= 1024) {
            setCardsPerPage(4); // Large screens (4 cards)
        } else if (window.innerWidth >= 640) {
            setCardsPerPage(2); // Tablet screens (2 cards)
        } else {
            setCardsPerPage(1); // Mobile screens (1 card)
        }
    };

    // Initial page load: Update cards per page based on window size
    useEffect(() => {
        updateCardsPerPage();
        window.addEventListener("resize", updateCardsPerPage);
        return () => window.removeEventListener("resize", updateCardsPerPage);
    }, []);

    const handleViewDetails = (test) => {
        navigate(`/popular-test/${test._id}`, { state: { test } });
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="pb-12 mt-8">
            <section className="px-4 sm:px-8">
                <div className="text-center mb-6">
                    <h2 className="text-2xl sm:text-3xl font-bold text-gray-800">
                        Popular Test
                    </h2>
                    <p className="text-xs sm:text-sm md:text-xl font-semibold text-gray-800">
                        Choose from our frequently booked blood tests
                    </p>
                </div>

                <div className="relative mt-6">
                    {/* Cards Section */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                        {tests.map((test) => (
                            <div
                                key={test._id}
                                onClick={() => handleViewDetails(test)}
                                className="rounded-lg p-6 shadow-md hover:shadow-lg transition-all duration-200 ease-in-out cursor-pointer"
                            >
                                <h3 className="text-lg font-semibold text-gray-700">
                                    {test.name}
                                </h3>
                                <p className="text-lg font-semibold text-black-600 mt-2">
                                    Rs. {test.amount}
                                </p>

                                <button
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent navigation when clicking "Add to Cart"
                                        addToCart(test);
                                    }}
                                    className={`text-white py-1 px-4 w-4/5 h-10 text-sm rounded-md ${cartItemIds.includes(test._id)
                                        ? "bg-green-600"
                                        : "bg-[#522f7c]"
                                        }`}
                                >
                                    {cartItemIds.includes(test._id)
                                        ? "Added to Cart"
                                        : "Add to Cart"}
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default PopularTestCollection;
