import React, { useEffect } from 'react';

const TermsAndConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="bg-gray-100 py-8 px-4 sm:px-8 lg:px-16">
            <h1 className="text-2xl font-bold text-gray-800 mb-6">Terms and Conditions</h1>
            <div className="space-y-6 text-gray-700">
                <p>
                    Welcome to Vallabhies Diagnostic. These Terms and Conditions ("Terms") outline the rules and regulations governing your use of our website and services. By accessing or using our website, you acknowledge that you have read, understood, and agreed to be bound by these Terms. If you do not agree with any part of these Terms, you are advised to refrain from using our services.
                </p>
                <p>
                    Vallabhies Diagnostic provides an online platform where users can book health tests, make payments, and receive diagnostic reports. Our services include collecting necessary samples, such as blood or urine, performing tests at our authorized laboratories, and delivering reports to users through the website. These Terms apply to all users of our website, including individuals who book tests, access reports, or interact with any other features offered on our platform.
                </p>
                <p>
                    To use our services, you must be at least 18 years old or have obtained the consent of a parent or legal guardian. By accessing the website, you confirm that the information you provide is accurate, complete, and truthful. Vallabhies Diagnostic reserves the right to deny or terminate access to our services if inaccurate or misleading information is provided.
                </p>
                <p>
                    Our platform operates with an OTP-based login system. Users are required to provide a valid mobile number to receive an OTP for authentication. This login mechanism ensures the security of user accounts. As a user, you are solely responsible for maintaining the confidentiality of your account credentials and ensuring that no unauthorized person gains access to your account. Vallabhies Diagnostic shall not be held liable for any unauthorized access resulting from your failure to secure your login information.
                </p>
                <p>
                    When booking a health test, you must select the test and complete the payment process to confirm your booking. Payments are processed through secure third-party payment gateways. It is your responsibility to ensure the accuracy of your payment details. Vallabhies Diagnostic is not responsible for payment errors or interruptions caused by external payment systems. Once payment is confirmed, the booking is finalized, and the sample collection process is initiated according to the selected test requirements.
                </p>
                <p>
                    As part of the service, users must provide samples required for the booked test. Sample collection is performed by trained professionals following medical safety standards. Users are required to follow the provided instructions for sample preparation to ensure the accuracy of test results. Vallabhies Diagnostic is not responsible for inaccurate results if the user fails to comply with these instructions.
                </p>
                <p>
                    Diagnostic reports are generated after the processing of the samples and are delivered through the platform. The delivery timeframe varies depending on the type of test. Vallabhies Diagnostic makes every effort to provide reports promptly but does not guarantee a specific timeline due to potential delays. Users should note that test results are for informational purposes only and are not a substitute for professional medical advice. It is the user’s responsibility to consult a qualified healthcare provider for interpretation and guidance based on the test results.
                </p>
                <p>
                    Once a test is booked and payment is made, cancellations or refunds are not allowed except in cases where Vallabhies Diagnostic is unable to provide the requested service. If such a situation arises, users may contact customer support to request assistance. Any applicable refunds will be processed in a reasonable timeframe and in accordance with Vallabhies Diagnostic's internal policies.
                </p>
                <p>
                    By using our platform, you agree to provide accurate information during booking, cooperate during sample collection, and use the diagnostic reports responsibly. Users are prohibited from using the services for any unlawful purpose, misrepresenting their identity, or engaging in activities that disrupt the platform or harm other users.
                </p>
                <p>
                    While Vallabhies Diagnostic strives to provide reliable and professional services, we are not liable for errors or inaccuracies in diagnostic reports resulting from factors beyond our control. Additionally, we are not responsible for any decisions or actions taken by users based on the test results without consulting a medical professional. Our liability, if any, is limited to the amount paid for the specific test in question.
                </p>
                <p>
                    The content on the Vallabhies Diagnostic website, including text, images, and software, is the intellectual property of Vallabhies Diagnostic. Unauthorized use, reproduction, or distribution of this content is strictly prohibited.
                </p>
                <p>
                    Your use of the platform is also governed by our Privacy Policy, which explains how we collect, use, and protect your personal and health-related information. By agreeing to these Terms, you also accept the terms outlined in our Privacy Policy.
                </p>
                <p>
                    Vallabhies Diagnostic reserves the right to update or amend these Terms at any time without prior notice. Any changes will be effective immediately upon being posted on this page. We encourage users to review these Terms periodically to stay informed of any updates. Continued use of our platform after changes are made constitutes acceptance of the revised Terms.
                </p>
                <p>
                    These Terms are governed by the laws of Indian Constitution. In the event of a dispute, users agree to first attempt resolution through discussions with Vallabhies Diagnostic. If unresolved, the matter will be referred to arbitration, with the arbitrator’s decision being final and binding.
                </p>
                <p>
                    If you have any questions or concerns about these Terms and Conditions or any aspect of our services, please contact us at <a href="mailto:info@vallabhiindia.com" className="text-blue-600 hover:underline">info@vallabhiindia.com</a>. Vallabhies Diagnostic is committed to providing a secure and reliable service and addressing any concerns promptly and effectively.
                </p>
                <p>
                    Thank you for choosing Vallabhies Diagnostic. By using our platform, you acknowledge and accept these Terms and Conditions.
                </p>
            </div>
        </div>
    );
};

export default TermsAndConditions;
