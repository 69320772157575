import React from 'react';
import { useNavigate } from 'react-router-dom';

function Footer() {
    const navigate = useNavigate();

    const show = [
        { id: 1, name: '10 Years of legacy', icon: 'https://toppng.com/uploads/preview/badge-vector-11551048691jv2ak42spf.png' },
        { id: 2, name: 'NABL & CAP Accredited Labs', icon: 'https://toppng.com/uploads/preview/badge-vector-11551048691jv2ak42spf.png' },
        { id: 3, name: 'Smart report with trend Analysis', icon: 'https://toppng.com/uploads/preview/badge-vector-11551048691jv2ak42spf.png' },
        { id: 4, name: 'Reports trusted by leading doctors and hospitals', icon: 'https://toppng.com/uploads/preview/badge-vector-11551048691jv2ak42spf.png' },
    ];

    return (
        <div className="bg-gray-100 p-4 lg:pb-24 md:pb-44 sm:pb-32 pb-32">
            {/* Main Section with Boxes */}
            <div className="flex flex-wrap justify-center gap-4 mb-6">
                {show.map((item) => (
                    <div
                        key={item.id}
                        className="bg-white p-4 rounded-lg shadow-lg flex items-center w-full sm:w-1/2 md:w-1/3 lg:w-1/5"
                    >
                        <img src={item.icon} alt={item.name} className="w-8 h-8 mr-2" />
                        <span className="text-sm">{item.name}</span>
                    </div>
                ))}
            </div>


            {/* Line Below Section */}
            <div className="my-4 border-t-2 border-gray-600"></div>

            {/* Footer Content Section */}
            <div className="container mx-auto py-6">
                <div className="flex flex-wrap justify-between gap-6">
                    {/* Links and Information */}
                    <div className="flex flex-col space-y-4 w-full sm:w-1/2 md:w-1/4">
                        <p className="font-semibold">About Us</p>
                        <p className="text-sm">
                            We are dedicated to providing quality reports and diagnostic services trusted by doctors and hospitals nationwide.
                        </p>
                    </div>

                    <div className="flex flex-col space-y-4 w-full sm:w-1/2 md:w-1/4">
                        <p className="font-semibold">Careers</p>
                        <p className="text-sm">Join our team and help us make a difference in healthcare. Visit our careers page to learn more.</p>
                    </div>

                    <div className="flex flex-col space-y-4 w-full sm:w-1/2 md:w-1/4">
                        <p className="font-semibold">Our Labs</p>
                        <p className="text-sm">We have accredited laboratories with the latest technology to ensure accurate and fast results.</p>
                    </div>

                    <div className="flex flex-col space-y-4 w-full sm:w-1/2 md:w-1/4">
                        <p className="font-semibold">Partners</p>
                        <p className="text-sm">We collaborate with leading healthcare institutions to ensure the best services for our customers.</p>
                    </div>
                </div>

                {/* Links */}
                <div className="flex justify-center flex-wrap gap-4 mt-6 text-sm">
                    <p className="cursor-pointer hover:underline" onClick={() => navigate('/privacy-policy')}>Privacy Policy</p>
                    <p className="cursor-pointer hover:underline" onClick={() => navigate('/refund-policy')}>Refund Policy</p>
                    <p className="cursor-pointer hover:underline" onClick={() => navigate('/termsandconditions')}>Terms and Conditions</p>
                </div>

                {/* Social Media Icons */}
                <div className="flex justify-center space-x-6 mt-8">
                    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-800">
                        <i className="fab fa-facebook-f text-2xl"></i>
                    </a>
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-800">
                        <i className="fab fa-twitter text-2xl"></i>
                    </a>
                    <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-800">
                        <i className="fab fa-linkedin-in text-2xl"></i>
                    </a>
                    <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-800">
                        <i className="fab fa-instagram text-2xl"></i>
                    </a>
                </div>

                {/* Copyright */}
                <div className="text-center mt-8 text-sm">
                    <p>&copy; 2024 Vallabhi Diagnosis. All rights reserved.</p>
                </div>
            </div>
        </div>
    );
}

export default Footer;
