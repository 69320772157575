import React, { useEffect } from 'react';
import HeartTest from "../../Images/HealthRiskImage/Heart.png";
import LiverTest from "../../Images/HealthRiskImage/Liver.png";
import KidneyTest from "../../Images/HealthRiskImage/Kidney.png";
import ThyroidTest from "../../Images/HealthRiskImage/Thyroid.png";
import JointTest from "../../Images/HealthRiskImage/Bone.png";
import { Link } from 'react-router-dom';

const HealthRiskNavbarLink = () => {
    const healthTest = [
        { id: 1, name: 'heart test', img: HeartTest, route: "/Heart Test" },
        { id: 2, name: 'Liver test', img: LiverTest, route: "/Liver Test" },
        { id: 3, name: 'Kidney test', img: KidneyTest, route: "/Kidney Test" },
        { id: 4, name: 'Thyroid test', img: ThyroidTest, route: "/Thyroid Test" },
        { id: 4, name: 'Joint test', img: JointTest, route: "/Bone And Joint Test" },
    ]
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='px-4 sm:px-8'>
            <section className="mb-16">
                <div className="flex justify-between items-center my-6">
                    <div className='bg-gray-100 p-4 border-2 border-gray-100 w-full rounded-lg'>
                        <h2 className="lg:text-3xl md:text-xl sm:text-lg text-lg font-bold mb-2 text-gray-800">Health Risk Tests</h2>
                        <p className="lg:text-lg md:text-md sm:text-sm text-sm font-semibold text-gray-800">Choose test as per your health risk</p>
                    </div>
                </div>

                {/* Single row with smaller cards */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 py-4">
                    {healthTest.map((test) => (
                        <div
                            key={test.id}
                            className="border-t-4 border-[#522f7c] p-6 shadow-lg rounded-lg hover:shadow-2xl transition duration-200 ease-in-out w-full cursor-pointer"
                        >
                            <Link to={test.route}>
                                <div className="flex flex-col items-center">
                                    {/* Circular frame for image */}
                                    <div className="w-32 h-32 rounded-full overflow-hidden mb-4">
                                        <img
                                            src={test.img || "image not found"}
                                            alt={test.name}
                                            className="w-full h-full object-cover bg-gray-200 p-4"
                                        />
                                    </div>

                                    {/* Test Name */}
                                    <h3 className="text-m font-bold text-gray-700">{test.name}</h3>
                                </div>
                            </Link>
                        </div>

                    ))}
                </div>
            </section>
        </div>
    );
};

export default HealthRiskNavbarLink