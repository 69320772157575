import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import constantApi from '../../constantApi';

const ManageAddress = () => {
    const location = useLocation();
    const userDetail = location.state;
    const navigate = useNavigate()

    const [selectedAddress, setSelectedAddress] = useState(null);

    const handleAddressSelect = (index) => {
        setSelectedAddress(index); // Store the index of the selected address
    };

    // Initialize state for address and form data
    const [address, setAddress] = useState([]);
    const [formData, setFormData] = useState({
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
    });
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Update address state when userDetail changes
    useEffect(() => {
        if (userDetail?.address?.length > 0) {
            setAddress(userDetail.address);
        }
    }, [userDetail]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const token = localStorage.getItem("token");

        const newAddress = {
            streetAddress: formData.streetAddress,
            city: formData.city,
            state: formData.state,
            postalCode: formData.postalCode,
            country: formData.country,
        };

        const updatedUserDetail = {
            ...userDetail,
            address: userDetail.address ? [...userDetail.address, newAddress] : [newAddress],
        };

        // Send the updated user detail to the backend
        axios.put(`${constantApi.baseUrl}user/`, updatedUserDetail, {
            headers: {
                "Authorization": token
            }
        }).then((response) => {
            navigate("/userprofile")
            setIsModalOpen(false);  // Close the modal after submission
        }).catch((err) => {
            console.log("error is ", err);
        });
    };

    return (
        <div className=' gap-4 bg-gray-100'>
            {/* Button to open the modal */}
            <div className="mt-4 flex  justify-end">
                <button
                    onClick={() => setIsModalOpen(true)}
                    className="px-8 py-3 text-lg font-semibold text-white bg-green-600 rounded-md shadow-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600"
                >
                    + Add Address
                </button>
            </div>
            {/* Address List */}
            <div className="bg-gray-200 p-4 rounded-lg max-w-lg mt-4 flex  justify-center items-center gap-10">
                {address.length > 0 ? (
                    address.map((detail, index) => (
                        <div key={index} className="mb-4 bg-white p-4 rounded-lg border border-gray-300 w-full">
                            {/* Radio Button to select an address */}
                            <div className="flex items-center mb-4">
                                <input
                                    type="radio"
                                    id={`address-${index}`}
                                    name="address"
                                    value={index}
                                    checked={selectedAddress === index}
                                    onChange={() => handleAddressSelect(index)}
                                    className="mr-2"
                                />
                                <label htmlFor={`address-${index}`} className="font-semibold text-gray-700">
                                    Select this address
                                </label>
                            </div>

                            {/* Address Details */}
                            <div>
                                <p><strong>City:</strong> {detail.city}</p>
                                <p><strong>Street:</strong> {detail.streetAddress}</p>
                                <p><strong>State:</strong> {detail.state}</p>
                                <p><strong>Postal Code:</strong> {detail.postalCode}</p>
                                <p><strong>Country:</strong> {detail.country}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-gray-600">No address found.</p>
                )}
            </div>



            {/* Modal for address form */}
            {isModalOpen && (
                <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
                    <div className="w-full max-w-3xl p-8 bg-white shadow-lg rounded-lg relative">
                        {/* Close Modal Button */}
                        <button
                            onClick={() => setIsModalOpen(false)}
                            className="absolute top-2 right-2 text-white bg-red-500 p-2 rounded-full hover:bg-red-700"
                        >
                            X
                        </button>

                        <h2 className="text-3xl font-semibold text-center text-indigo-600 mb-8">Add New Address</h2>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            {/* Street Address */}
                            <div>
                                <label htmlFor="streetAddress" className="block text-lg font-medium text-gray-700">Street Address</label>
                                <input
                                    type="text"
                                    id="streetAddress"
                                    name="streetAddress"
                                    value={formData.streetAddress}
                                    onChange={handleChange}
                                    required
                                    className="w-full px-6 py-3 mt-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                                />
                            </div>

                            {/* City and State */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <div>
                                    <label htmlFor="city" className="block text-lg font-medium text-gray-700">City</label>
                                    <input
                                        type="text"
                                        id="city"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-6 py-3 mt-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="state" className="block text-lg font-medium text-gray-700">State</label>
                                    <input
                                        type="text"
                                        id="state"
                                        name="state"
                                        value={formData.state}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-6 py-3 mt-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                                    />
                                </div>
                            </div>

                            {/* Postal Code and Country */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <div>
                                    <label htmlFor="postalCode" className="block text-lg font-medium text-gray-700">Postal Code</label>
                                    <input
                                        type="text"
                                        id="postalCode"
                                        name="postalCode"
                                        value={formData.postalCode}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-6 py-3 mt-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="country" className="block text-lg font-medium text-gray-700">Country</label>
                                    <input
                                        type="text"
                                        id="country"
                                        name="country"
                                        value={formData.country}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-6 py-3 mt-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                                    />
                                </div>
                            </div>

                            {/* Submit Button */}
                            <div className="flex justify-center">
                                <button
                                    type="submit"
                                    className="px-8 py-3 mt-4 text-lg font-semibold text-white bg-indigo-600 rounded-md shadow-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ManageAddress;
