import React from "react";
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const TestComponent = () => {
    // Create an array of 10 cards
    const cards = [
        { id: 1, name: "Card 1" },
        { id: 2, name: "Card 2" },
        { id: 3, name: "Card 3" },
        { id: 4, name: "Card 4" },
        { id: 5, name: "Card 5" },
        { id: 6, name: "Card 6" },
        { id: 7, name: "Card 7" },
        { id: 8, name: "Card 8" },
        { id: 9, name: "Card 9" },
        { id: 10, name: "Card 10" },
    ];

    // Slick slider settings
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Default: 4 cards
        slidesToScroll: 1,
        nextArrow: <FaAngleRight className="slick-next slick-arrow" />,
        prevArrow: <FaAngleLeft className="slick-prev slick-arrow" />,
        responsive: [
            {
                breakpoint: 1024, // Tablet and smaller screens
                settings: {
                    slidesToShow: 2, // Show 2 cards on tablets
                },
            },
            {
                breakpoint: 640, // Mobile screens
                settings: {
                    slidesToShow: 1, // Show 1 card on mobile
                },
            },
        ],
    };

    return (
        <div className="pb-12">
            <section className="px-4 sm:px-8">
                <div className="flex justify-between items-center mb-6">
                    <div>
                        <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 text-center sm:text-left">
                            Test Cards
                        </h2>
                        <p className="text-xs sm:text-sm md:text-xl font-semibold text-gray-800 text-center sm:text-left">
                            Swipe through the cards!
                        </p>
                    </div>
                </div>

                <div className="relative mt-6">
                    {/* Slick Slider */}
                    <Slider {...settings}>
                        {cards.map((card) => (
                            <div
                                key={card.id}
                                className="border border-gray-300 rounded-lg p-6 shadow-md hover:shadow-lg transition-all duration-200 ease-in-out"
                            >
                                <h3 className="text-lg font-semibold text-gray-700">{card.name}</h3>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        </div>
    );
};

export default TestComponent;
