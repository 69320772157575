import React, { createContext, useState, useContext } from 'react';

// Create the context for user details
const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
    const [userDetail, setUserDetail] = useState({
        countrycode: '',
        phone: '',
        url: '',
        isnewuser: '',
        name: "",
        dob: "",
        email: ""
    });

    return (
        <UserContext.Provider value={{ userDetail, setUserDetail }}>
            {children}
        </UserContext.Provider>
    );
};

// Create a custom hook to use the user context
export const useUser = () => {
    return useContext(UserContext);
};
