import React, { useEffect } from 'react';

const RefundPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="container mx-auto p-6">
            <h1 className="text-2xl font-bold text-center mb-4">Refund Policy</h1>
            <p className="mb-4">
                At Vallabhies Diagnostic, we are committed to providing transparent and fair policies regarding refunds. This Refund Policy outlines the conditions under which refunds may be issued and the procedures involved. By using our services, you acknowledge and agree to the terms described in this policy.
            </p>
            <h2 className="text-lg font-semibold mb-2">Booking Not Confirmed</h2>
            <p className="mb-4">
                If your test booking is not confirmed due to technical or operational issues, but your payment has been debited, we will ensure that the full amount is refunded to you. Refunds in such cases are processed promptly and are generally credited back to your original payment method within 5 to 7 business days. However, the exact timeframe may vary depending on your bank or payment provider.
            </p>
            <h2 className="text-lg font-semibold mb-2">Dissatisfaction with Reports</h2>
            <p className="mb-4">
                Refunds are not offered based on dissatisfaction alone. Diagnostic tests are conducted in compliance with established medical standards. If you believe there is an issue with the accuracy of the report, you may request a review, and we may re-perform the test at no additional cost to you. However, refunds will not be provided in these cases.
            </p>
            <h2 className="text-lg font-semibold mb-2">Cancellations</h2>
            <p className="mb-4">
                If you decide to cancel the test after booking, please contact us within **2 to 3 hours** via email at **info@vallabhiindia.com**. Once your cancellation is confirmed, a refund will be processed within **5 to 7 working days**, although the exact timeframe may vary depending on your bank. Cancellations requested after **3 hours** of booking will not be accepted. Additionally, if the patient's sample has already been collected, the order cannot be canceled, and no refund will be provided under such circumstances.
            </p>
            <h2 className="text-lg font-semibold mb-2">Non-Refundable Circumstances</h2>
            <p className="mb-4">
                Refunds will not be provided for situations such as failure to provide required samples, arriving late for sample collection, or non-compliance with pre-test instructions.
            </p>
            <h2 className="text-lg font-semibold mb-2">Contact Us</h2>
            <p className="mb-4">
                For inquiries regarding refunds, contact our support team at <a href="mailto:info@vallabhiindia.com" className="text-blue-500">info@vallabhiindia.com</a>. Our team is available to assist you and provide clarification regarding refund eligibility and procedures.
            </p>
        </div>
    );
};

export default RefundPolicy;
