import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper/modules";
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleLeft } from "react-icons/fa";

import Alcohol from "../Images/HealthConditionImage/Alcohol.png"
import Allergy from "../Images/HealthConditionImage/Allergy.png"
import Anemia from "../Images/HealthConditionImage/Anemia.png"
import Arthritis from "../Images/HealthConditionImage/Arthritis.png"
import Cancer from "../Images/HealthConditionImage/Cancer.png"
import Diabetes from "../Images/HealthConditionImage/Diabetes.png"
import Fever from "../Images/HealthConditionImage/Fever.png"
import Hepatitis from "../Images/HealthConditionImage/Hepatitis.png"
import Hormones from "../Images/HealthConditionImage/Hormones.png"
import Obesity from "../Images/HealthConditionImage/Obesity.png"
import Vitamins from "../Images/HealthConditionImage/Vitamins.png"
import Pregnancy from "../Images/HealthConditionImage/Pregnancy.png"
import { Link, useNavigate } from 'react-router-dom';
const HealthConditionTest = () => {
    const navigate = useNavigate();

    const healthCondition = [
        { id: 1, name: 'Alchohalism', img: Alcohol, route: "/Blood-alchohal-test" },
        { id: 2, name: 'Allergy', img: Allergy, route: "/Allergy-test" },
        { id: 3, name: 'Anemia', img: Anemia, route: "/Anemia-test" },
        { id: 4, name: 'Arthritis', img: Arthritis, route: "/Arthritis-test" },
        { id: 5, name: 'Diabities', img: Diabetes, route: "/Diabities-test" },
        { id: 6, name: 'Fever', img: Fever, route: "/Fever-test" },
        { id: 7, name: 'Hepatitis', img: Hepatitis, route: "/Hepatitis-test" },
        { id: 8, name: 'Hormones', img: Hormones, route: "/Hormones-test" },
        { id: 9, name: 'Obesity', img: Obesity, route: "/Obesity-test" },
        { id: 10, name: 'Vitamin', img: Vitamins, route: "/Vitamins-test" },
        { id: 11, name: 'pregnancy', img: Pregnancy, route: "/Pregnancy-test" },
    ]


    const handleClick = (testName) => {
        navigate(`/test/${testName}`); // Navigate to the dynamic test route
    };
    return (
        <div className='px-4 sm:px-8'>
            <section className="mb-16">
                <div className="flex justify-between items-center mb-6">
                    <div>
                        <h2 className="lg:text-3xl md:text-xl sm:text-lg text-xl  font-bold mb-2 text-gray-800">Tests By Health Condition</h2>
                        <p className="lg:text-lg md:text-md sm:text-sm text-sm  font-semibold text-gray-800">Choose test as per your health condition</p>
                    </div>
                    <div>
                        <Link to="/health-conditions" className="text-black">

                            <button className="border border-[#522f7c] text-[#522f7c] py-1 px-4 text-sm rounded-md hover:bg-purple-700 hover:text-white transition duration-200 ease-in-out">

                                View More
                            </button>
                        </Link>
                    </div>
                </div>

                <div className="relative mx-2">
                    {/* Left Scroll Button */}
                    <button
                        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-[#522f7c] text-white p-3 rounded-full shadow hover:bg-[#522f7c] transition z-10 hidden sm:block"
                        onClick={() => {
                            document.getElementById('scrollContainer').scrollBy({ left: - 360, behavior: 'smooth' });
                        }}
                    >
                        <FaAngleLeft />
                    </button>

                    {/* Right Scroll Button */}
                    <button
                        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-[#522f7c] text-white p-3 rounded-full shadow hover:bg-[#ca8fde] transition z-10 hidden sm:block"
                        onClick={() => {
                            document.getElementById('scrollContainer').scrollBy({ left: 360, behavior: 'smooth' });
                        }}
                    >
                        <FaAngleRight />
                    </button>

                    {/* Scrollable container */}
                    <div
                        id="scrollContainer"
                        className="flex overflow-x-auto space-x-8 py-4  scrollbar-hide"
                    >
                        {healthCondition.map((test) => (
                            <div
                                onClick={() => handleClick(test.name)}

                                key={test.id}
                                className="border-t-4 border-[#522f7c] p-6 shadow-lg rounded-lg hover:shadow-2xl transition duration-200 ease-in-out w-full cursor-pointer"
                            >
                                <div className="flex flex-col items-center w-72">
                                    {/* Circular frame for image */}
                                    <div className="w-28 h-28 rounded-full overflow-hidden mb-4">
                                        <img
                                            src={test.img || "image not found"}
                                            alt={test.name}
                                            className="w-full h-full object-cover bg-purple-100 p-4"
                                        />
                                    </div>

                                    {/* Test Name */}
                                    <h3 className="text-lg font-bold text-gray-700">{test.name}</h3>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HealthConditionTest
