import React, { useState } from "react";

function HomeVisit() {
  // Form State
  const [formData, setFormData] = useState({
    fullName: "",
    mobileNumber: "",
    city: "",
    state: "",
    testName: "",
  });

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);
    alert("Form submitted successfully!");
    setFormData({
      fullName: "",
      mobileNumber: "",
      city: "",
      state: "",
      testName: "",
    });
  };

  return (
    <div className="bg-gray-50 min-h-screen p-8">
      {/* Header */}
      <header className="text-center py-6 bg-blue-900 text-white rounded-lg shadow-md mb-8">
        <h1 className="text-3xl font-bold">Blood Test at Home</h1>
        <p className="mt-2 text-lg">
          Convenient, fast, and accurate blood tests from the comfort of your home.
        </p>
      </header>

      {/* Main Section */}
      <div className="flex flex-col lg:flex-row gap-8 max-w-7xl mx-auto">
        {/* Left Content */}
        <div className="lg:w-2/3 space-y-8">
          <section className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold text-blue-900">Why Choose Us?</h2>
            <ul className="list-disc mt-4 pl-6 text-gray-700 space-y-2">
              <li>Trusted professionals for sample collection.</li>
              <li>Fast and accurate results delivered online.</li>
              <li>Hygienic and safe testing process.</li>
              <li>4000+ Tests and Profiles</li>
              <li>Accurate Reports on WhatsApp and Email in 24 hours*.</li>
              <li>Service available even on Sundays.</li>
            </ul>
          </section>

          <section className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold text-blue-900">How It Works</h2>
            <div className="mt-4 space-y-4">
              <div className="flex items-start space-x-4">
                <span className="text-xl font-bold text-blue-900">1.</span>
                <p className="text-gray-700">Book your test online or via our app.</p>
              </div>
              <div className="flex items-start space-x-4">
                <span className="text-xl font-bold text-blue-900">2.</span>
                <p className="text-gray-700">
                  A certified phlebotomist will visit your home for sample collection.
                </p>
              </div>
              <div className="flex items-start space-x-4">
                <span className="text-xl font-bold text-blue-900">3.</span>
                <p className="text-gray-700">
                  Get your results within 24 hours, directly on your device.
                </p>
              </div>
            </div>
          </section>
        </div>

        {/* Right Form */}
        <div className="lg:w-1/3 bg-white p-6 rounded-lg shadow-md">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="fullName" className="block text-gray-700 font-medium">
                Full Name
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your full name"
                required
              />
            </div>
            <div>
              <label htmlFor="mobileNumber" className="block text-gray-700 font-medium">
                Mobile Number
              </label>
              <input
                type="tel"
                id="mobileNumber"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your mobile number"
                required
              />
            </div>
            <div>
              <label htmlFor="city" className="block text-gray-700 font-medium">
                City
              </label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your city"
                required
              />
            </div>
            <div>
              <label htmlFor="state" className="block text-gray-700 font-medium">
                State
              </label>
              <input
                type="text"
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your state"
                required
              />
            </div>
            <div>
              <label htmlFor="testName" className="block text-gray-700 font-medium">
                Name of the Test
              </label>
              <input
                type="text"
                id="testName"
                name="testName"
                value={formData.testName}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter the name of the test"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-900 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-700 transition duration-300"
            >
              Book Your Test
            </button>
          </form>
        </div>
      </div>

      {/* Terms and Conditions */}
      <div className="mt-8 max-w-7xl mx-auto bg-gray-100 p-6 rounded-lg shadow-md text-gray-700">
        <h3 className="text-lg font-semibold mb-4">Terms and Conditions</h3>
        <p className="text-sm">
          By booking a test, you agree to our terms and conditions. Our certified professionals
          ensure the highest standards of hygiene and safety. The results provided are for
          informational purposes only and should not replace professional medical advice.
        </p>
        <p className="text-sm mt-2">
          Please ensure that the information provided is accurate. Any false information may lead
          to a delay in processing your request. For further details, contact our customer service.
        </p>
      </div>
    </div>
  );
}

export default HomeVisit;
