import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCart } from '../../context/CartContext';
import axios from 'axios';
import constantApi from '../../constantApi';

const HealthRiskCollection = () => {
    const { category } = useParams(); // Get the category from the URL
    const [tests, setTests] = useState([]);
    const navigate = useNavigate();
    const { addToCart, cartItemIds } = useCart();

    useEffect(() => {
        axios
            .get(`${constantApi.baseUrl}user/product/${category}`)
            .then((response) => {
                setTests(response.data.data);
            })
            .catch((error) => {
                console.error("Error fetching tests: ", error);
            });
    }, [category]);

    const handleViewDetails = (test) => {
        navigate(`/${category.toLowerCase().replace(/\s+/g, '-')}/${test._id}`, { state: { test } });
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="pb-12 mt-8">
            <section className="px-4 sm:px-8">
                <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center sm:text-left">
                    {category}
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                    {tests.map((test) => (
                        <div key={test._id} className="w-full">
                            <div
                                onClick={() => handleViewDetails(test)}
                                className="border border-gray-300 rounded-lg p-6 shadow-md hover:shadow-lg transition-all duration-200 ease-in-out cursor-pointer"
                            >
                                <div className="flex justify-between items-center">
                                </div>
                                <h3 className="text-lg font-semibold text-gray-700">{test.name}</h3>
                                <p className="text-lg font-semibold text-black-600 mt-2">
                                    Rs. {test.amount}
                                </p>

                                <div className="flex justify-between mt-4">
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent navigation when clicking "Add to Cart"
                                            addToCart(test);
                                        }}
                                        className={`text-white py-1 px-4 w-4/5 h-10 text-sm rounded-md ${cartItemIds.includes(test._id) ? "bg-green-600" : "bg-[#522f7c]"
                                            }`}
                                    >
                                        {cartItemIds.includes(test._id) ? "Added to Cart" : "Add to Cart"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default HealthRiskCollection;
