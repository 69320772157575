import React, { useState } from "react";

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null); // Track the currently active accordion

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index); // Toggle accordion state
    };

    const FAQs = [
        {
            Question: "What is the Purpose of the Website?",
            Answer: "This Website provides the best services across the country and 100% genuine test results at an affordable price.",
        },
        {
            Question: "What type of services does Vallabhies Diagnostic offer?",
            Answer: "Vallabhies offer a wide range of diagnostic services and tests for almost every category, like for heart, liver, kidney, allergy, etc. We also have packages for men, women, and children that include full health checkup tests with 100% accurate results.",
        },
        {
            Question: "What are the precautions we need to take before the test?",
            Answer: "Precautions may vary depending upon the type of test. Instructions are provided for preparation of the specific test when you go for booking.",
        },
        {
            Question: "How much time does it take to get results of the test?",
            Answer: "The time may vary depending upon the test, but we provide the estimated time during the test booking confirmation. You can also inquire on the customer care number.",
        },
        {
            Question: "Where will I find my report of the test?",
            Answer: "Once your report is ready for the test, it will be available on the dashboard, and you can download it easily from there.",
        },
        {
            Question: "How do I ensure the test is accurate or not?",
            Answer: "At Vallabhies, accuracy is our top priority. Our team of highly skilled professionals ensures that every test undergoes a meticulous review process. Each report is carefully validated to guarantee precision and reliability.",
        },
        {
            Question: "What is the timing of booking the test?",
            Answer: "We have an online booking option. You can order your test anytime, and during operational hours, our person will come to collect the samples for the respective test.",
        },
        {
            Question: "Are these reports valid for other hospitals?",
            Answer: "Yes, the reports from Vallabhies are accurate, and tests are performed by professionals to ensure their validity in almost every hospital.",
        },
        {
            Question: "Are your lab technicians certified?",
            Answer: "Yes, Vallabhies employ experienced and certified technicians who are trained to provide the best services and accurate test results.",
        },
    ];

    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            <h2 className="text-2xl font-bold mb-4 text-gray-700">Frequently Asked Questions</h2>
            {FAQs.map((faq, index) => (
                <div key={index} className="border-b py-4">
                    <button
                        onClick={() => toggleAccordion(index)}
                        className="flex justify-between items-center w-full text-left text-gray-700 focus:outline-none"
                    >
                        <span className="text-lg font-medium">{faq.Question}</span>
                        <span
                            className={`transform transition-transform ${activeIndex === index ? "rotate-180" : ""
                                }`}
                        >
                            ▼
                        </span>
                    </button>
                    {activeIndex === index && (
                        <p className="mt-2 text-gray-600 text-sm">{faq.Answer}</p>
                    )}
                </div>
            ))}
        </div>
    );
};

export default FAQ;
