import React from "react";

const BottomNavbar = () => {
    return (
        <div className="fixed bottom-0 left-0 w-full bg-[#522f7c] text-white mx-auto">
            {/* Content for large devices */}
            <div className="hidden lg:flex  items-center justify-center gap-8 p-4 mx-auto space-y-4">
                {/* Text Content */}
                <div className="text-center">
                    <h1 className="font-bold lg:text-xl md:text-lg sm:text-md text-md">
                        Do you have any queries?
                    </h1>
                </div>

                {/* Get a Call Back Button */}
                <div className="text-center">
                    <button className="bg-yellow-500 text-black font-bold lg:text-xl md:text-lg sm:text-md text-md px-4 rounded-xl py-2">
                        Get a call Back
                    </button>
                </div>

                {/* Call Us Now Text */}
                <div className="text-center">
                    <h1 className="font-bold lg:text-xl md:text-lg sm:text-md text-md">
                        or Call us now at
                    </h1>
                </div>

                {/* Phone Number Button */}
                <div className="text-center">
                    <a href="tel:+9876543210">
                        <button className="bg-yellow-500 font-bold text-lg text-black px-6 py-2 rounded-lg">
                            9876543210
                        </button>
                    </a>
                </div>
            </div>

            {/* Content for small devices */}
            <div className="flex flex-col lg:hidden justify-center p-4 items-center">
                <div>
                    <p className="text-2xl font-bold">Book a Home visit now</p>
                </div>
                <div>
                    <a href="tel:+9876543210">
                        <button className="bg-yellow-500 font-bold text-lg text-black px-6 py-4 rounded-lg">
                            9876543210
                        </button>
                    </a>
                </div>


            </div>
        </div>
    );
};

export default BottomNavbar;
