import React from "react";

const WhyChooseUs = () => {
  return (
    <div className="flex flex-wrap justify-between items-center text-center bg-blue-100 mb-16 py-8">
      <div className="w-full md:w-1/2 lg:w-1/5 px-4 mb-4 lg:mb-0">
        <h2 className="text-lg md:text-xl font-bold text-gray-800 mb-2">25+ Years of Experience</h2>
        <p className="text-gray-600 text-sm md:text-base">Trusted expertise in delivering reliable healthcare services.</p>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/5 px-4 mb-4 lg:mb-0">
        <h2 className="text-lg md:text-xl font-bold text-gray-800 mb-2">Pan India Presence</h2>
        <p className="text-gray-600 text-sm md:text-base">Extensive network across all major cities and regions.</p>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/5 px-4 mb-4 lg:mb-0">
        <h2 className="text-lg md:text-xl font-bold text-gray-800 mb-2">Advanced Clinical Labs</h2>
        <p className="text-gray-600 text-sm md:text-base">Equipped with cutting-edge technology for accurate diagnostics.</p>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/5 px-4 mb-4 lg:mb-0">
        <h2 className="text-lg md:text-xl font-bold text-gray-800 mb-2">10,000+ Patient Touchpoints</h2>
        <p className="text-gray-600 text-sm md:text-base">Ensuring easy accessibility and seamless care.</p>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/5 px-4">
        <h2 className="text-lg md:text-xl font-bold text-gray-800 mb-2">Comprehensive Tests & Profiles</h2>
        <p className="text-gray-600 text-sm md:text-base">Wide range of diagnostic tests tailored for every need.</p>
      </div>
    </div>
  );
};

export default WhyChooseUs;
