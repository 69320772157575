import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="bg-gray-100 py-8 px-4 sm:px-8 lg:px-16">
            <h1 className="text-2xl font-bold text-gray-800 mb-6">Privacy Policy for Vallabhies Diagnostic</h1>
            <div className="space-y-6 text-gray-700">
                <p>
                    At Vallabhies Diagnostic, we are dedicated to protecting the privacy and confidentiality of our users. This Privacy Policy explains how we collect, use, and safeguard your personal and health-related information when you use our website to book health tests, provide samples, and receive diagnostic reports. By accessing or using our services, you consent to the practices described in this Privacy Policy.
                </p>
                <h2 className="text-lg font-semibold text-gray-800 mt-4">Information We Collect</h2>
                <p>
                    When you use our website, we collect various types of information to ensure seamless service delivery and a personalized experience. The information we collect includes personal details such as your mobile number, which is used for authentication through a secure OTP-based login system. We also collect information related to the health tests you book, including the type of test and any associated preferences. If you provide samples such as blood, urine, or other required materials for testing, these are collected and processed securely by our authorized labs.
                </p>
                <p>
                    Additionally, when you make a payment through our website, we collect the necessary payment information. Payment transactions are processed via secure third-party gateways to ensure the safety of your financial data. Furthermore, we automatically gather certain technical data, such as your IP address, browser type, and usage patterns, to improve the functionality and performance of our website.
                </p>
                <h2 className="text-lg font-semibold text-gray-800 mt-4">How We Use Your Information</h2>
                <p>
                    The information we collect is used solely to provide and improve our services. Your mobile number enables a quick and secure login process. Information about the health tests you book is used to process your request, collect the necessary samples, and conduct the tests. Once the tests are completed, the results are securely delivered to you.
                </p>
                <p>
                    We use payment information to confirm transactions and maintain records for accounting and compliance purposes. Technical data such as IP addresses and usage patterns help us analyze website performance, enhance user experience, and address technical issues. All collected information is handled with the utmost care and confidentiality.
                </p>
                <h2 className="text-lg font-semibold text-gray-800 mt-4">Sharing of Information</h2>
                <p>
                    We take your privacy seriously and ensure that your personal information is not shared with unauthorized parties. However, we may share your information under specific circumstances. For instance, the details of your booked health tests and collected samples are shared with authorized laboratories for processing and report generation. In rare cases, if required by law or in response to a valid legal request, we may disclose certain information to comply with legal obligations.
                </p>
                <h2 className="text-lg font-semibold text-gray-800 mt-4">Data Security</h2>
                <p>
                    We implement stringent security measures to protect your personal and health-related information. Our website uses secure OTP-based login to authenticate users and prevent unauthorized access. Sensitive data, including payment information, is encrypted to safeguard it during transmission and storage. Additionally, we conduct regular security audits to identify and address potential vulnerabilities.
                </p>
                <p>
                    While we strive to ensure the highest level of security, it is important to note that no method of electronic transmission or storage is completely secure. Therefore, while we take all reasonable measures to protect your data, we cannot guarantee absolute security.
                </p>
                <h2 className="text-lg font-semibold text-gray-800 mt-4">Your Rights</h2>
                <p>
                    As a user, you have the right to access and review your personal data and test reports. If you believe that any information we have about you is inaccurate or incomplete, you can request corrections. You may also request the deletion of your personal data where applicable, although this may impact our ability to provide certain services. Additionally, you have the right to withdraw your consent for data processing, but please note that this may limit your access to certain features or services on our website.
                </p>
                <p>
                    If you wish to exercise any of these rights, you can contact us directly using the details provided in this policy.
                </p>
                <h2 className="text-lg font-semibold text-gray-800 mt-4">Cookies and Tracking Technologies</h2>
                <p>
                    To improve website performance and provide a better user experience, we may use cookies and similar tracking technologies. These technologies collect information about how you use our website, such as the pages you visit and the duration of your session. You can manage your cookie preferences through your browser settings. By continuing to use our website, you consent to the use of cookies as described in this policy.
                </p>
                <h2 className="text-lg font-semibold text-gray-800 mt-4">Changes to This Privacy Policy</h2>
                <p>
                    We reserve the right to update this Privacy Policy at any time to reflect changes in our practices, legal requirements, or for other reasons. Any changes will be posted on this page, and the "Effective Date" at the top of this policy will be updated accordingly. We encourage you to review this page periodically to stay informed about how we protect your information.
                </p>
                <h2 className="text-lg font-semibold text-gray-800 mt-4">Contact Us</h2>
                <p>
                    If you have any questions, concerns, or feedback regarding this Privacy Policy or our data protection practices, please feel free to reach out to us. You can contact us via mail at{' '}
                    <a href="mailto:info@vallabhiindia.com" className="text-blue-600 hover:underline">
                        info@vallabhiindia.com
                    </a>
                    . Your privacy is of utmost importance to us, and we are committed to addressing any concerns you may have promptly and transparently.
                </p>
                <p>
                    Thank you for choosing Vallabhies Diagnostic. Your health and privacy remain our top priorities.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
