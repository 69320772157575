import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useCart } from '../../context/CartContext';
import axios from 'axios';
import constantApi from '../../constantApi';

function HealthPackageCollection() {
    const { name } = useParams();
    const [tests, setTests] = useState([]);
    const { addToCart, cartItemIds } = useCart();
    const navigate = useNavigate();

    let category = "health-packages"

    useEffect(() => {
        axios
            .get(`${constantApi.baseUrl}user/product/${category}`)
            .then((response) => {
                console.log("response is", response);
                setTests(response.data.data);
            })
            .catch((error) => {
                console.log("error is", error);
            });
    }, []);

    const handleViewDetails = (test) => {
        navigate(`/${category}/${test._id}`, { state: { test } });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="pb-12">
            <section>
                <div className="flex justify-between items-center my-6 mx-8">
                    <div className='bg-gray-100 p-4 border-2 border-gray-100 w-full rounded-lg'>
                        <h2 className="lg:text-3xl md:text-xl sm:text-lg text-lg font-bold mb-2 text-gray-800">{category}</h2>
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 px-4 md:px-8">
                    {tests.length > 0 ? (
                        tests.map((test) => (
                            <div key={test.id} className="w-full">
                                <div
                                    onClick={() => handleViewDetails(test)}
                                    className="border border-gray-300 rounded-lg p-4 md:p-6 shadow-md hover:shadow-lg transition-all duration-200 ease-in-out cursor-pointer"
                                >
                                    <div className="flex justify-between items-center mb-4">
                                        <img
                                            src="https://cdn-icons-png.flaticon.com/512/2965/2965567.png"
                                            alt="test"
                                            className="w-10 h-10 md:w-12 md:h-12"
                                        />
                                        <div className="text-sm font-semibold text-white bg-blue-700 flex items-center rounded-xl w-8 md:w-10 justify-center">
                                            {test.rating > 0 ? test.rating : '0'}{' '}
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                className="w-4 h-4 md:w-5 md:h-5 text-white ml-1"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M12 17.75l-3.5 2.3.8-4.2-3.2-2.8 4.3-.5 1.6-4 1.6 4 4.3.5-3.2 2.8.8 4.2L12 17.75z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <h3 className="text-base md:text-lg font-semibold text-gray-700">
                                        {test.name}
                                    </h3>
                                    <p className="text-sm md:text-lg font-semibold text-black-600 mt-2">
                                        Rs. {test.amount}
                                    </p>

                                    <div className="flex justify-between mt-4">
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent navigation when clicking "Add to Cart"
                                                addToCart(test);
                                            }}
                                            className={`text-white py-1 px-4 w-full text-xs md:text-sm rounded-md ${cartItemIds.includes(test._id)
                                                ? 'bg-green-600'
                                                : 'bg-[#522f7c]'
                                                }`}
                                        >
                                            {cartItemIds.includes(test._id)
                                                ? 'Added to Cart'
                                                : 'Add to Cart'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-center col-span-full">No data available for this test.</p>
                    )}
                </div>
            </section>
        </div>
    );
}

export default HealthPackageCollection;
