import React, { useState, useEffect } from "react";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from "react-router-dom";
import HeaderLocation from "./HeaderLocation";
import { CgProfile } from "react-icons/cg";

const Header = () => {
    const openWhatsApp = () => {
        const phoneNumber = "919876543210"; // Your phone number with country code
        const message = "Hi! I have a query about your services.";
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <div>
            <div className="bg-[#522f7c] text-white flex h-20 items-center justify-between px-4 py-2 hidden lg:flex header">
                <div className="font-bold lg:text-4xl md:text-lg sm:text-md text-md">
                    <Link to="/">Vallabhies Diagnostic</Link>
                </div>

                <div className="flex justify-center items-center">
                    <div className="">
                        <HeaderLocation />
                    </div>
                    <div className="flex-grow max-w-md relative">
                        <input
                            type="text"
                            placeholder="Search for Tests, Health Checkups"
                            className="w-full px-4 py-2 rounded-e-xl text-gray-700 pl-10"
                        />
                        <i className="fas fa-search absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"></i>
                    </div>
                </div>

                <div className="flex items-center space-x-2">
                    <i className="fab fa-whatsapp text-xl  text-green-500"></i>
                    {/* <span>Customer Care: <b>8422-801-801</b></span> */}
                    <button
                        onClick={openWhatsApp}
                        className="font-bold"
                    >
                        Customer Care: +91 9876543210
                    </button>
                </div>
                <div className="flex items-center space-x-2">
                    <i className="fas fa-phone-alt text-xl"></i>
                    <span>Home Visit: <b>9982-782-555</b></span>
                </div>
            </div>
        </div>
    );
};

export default Header;
