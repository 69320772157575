import React, { useEffect, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCart } from "../context/CartContext";
import constantApi from "../constantApi";

const HealthPackage = () => {
    const [tests, setTests] = useState([]);
    const navigate = useNavigate();
    const { addToCart, cartItemIds } = useCart();

    useEffect(() => {
        axios
            .get(`${constantApi.baseUrl}user/product/health-packages`)
            .then((response) => {
                setTests(response.data.data);
            })
            .catch((error) => {
                console.error("Error fetching health packages: ", error);
            });
    }, []);

    const handleViewDetails = (test) => {
        navigate(`/health-package/${test._id}`, { state: { test } });
    };

    return (
        <div className='px-4 sm:px-8 mt-4'>
            <section className="mb-4">
                <div className="flex justify-between items-center mb-6">
                    <div>
                        <h2 className="lg:text-3xl md:text-xl sm:text-lg text-xl  font-bold mb-2 text-gray-800">Health Packages</h2>
                        <p className="lg:text-lg md:text-md sm:text-sm text-sm  font-semibold text-gray-800">Comprehensive health tests tailored for you</p>
                    </div>
                </div>

                <div className="relative mx-2">
                    {/* Left Scroll Button */}
                    <button
                        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-[#522f7c] text-white p-3 rounded-full shadow hover:bg-[#6a3fad] transition z-10 hidden sm:block"
                        onClick={() => {
                            document.getElementById('Healthpackage').scrollBy({ left: -360, behavior: 'smooth' });
                        }}
                    >
                        <FaAngleLeft />
                    </button>

                    {/* Right Scroll Button */}
                    <button
                        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-[#522f7c] text-white p-3 rounded-full shadow hover:bg-[#6a3fad] transition z-10 hidden sm:block"
                        onClick={() => {
                            document.getElementById('Healthpackage').scrollBy({ left: 360, behavior: 'smooth' });
                        }}
                    >
                        <FaAngleRight />
                    </button>

                    {/* Scrollable container */}
                    <div
                        id="Healthpackage"
                        className="flex overflow-x-auto space-x-8 py-4 scrollbar-hide"
                    >
                        {tests.map((test) => (
                            <div
                                key={test._id}
                                onClick={() => handleViewDetails(test)}
                                className="border-t-4 border-[#522f7c] p-6 shadow-lg rounded-lg hover:shadow-2xl transition duration-200 ease-in-out w-full cursor-pointer"
                            >
                                <div className="flex flex-col items-center w-72">
                                    <h3 className="text-lg font-bold text-gray-700">{test.name}</h3>
                                    <p className="text-lg font-semibold text-gray-800 mt-2">Rs. {test.amount}</p>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent navigation when clicking "Add to Cart"
                                            addToCart(test);
                                        }}
                                        className={`text-white py-1 px-4 w-4/5 h-10 text-sm rounded-md ${cartItemIds.includes(test._id) ? "bg-green-600" : "bg-[#522f7c]"}`}
                                    >
                                        {cartItemIds.includes(test._id) ? "Added to Cart" : "Add to Cart"}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HealthPackage;
