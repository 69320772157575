import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import booking from "../../Images/userDashboard_img/booking-New.png";
import sampleTracking from "../../Images/userDashboard_img/tracking.png";
import report from "../../Images/userDashboard_img/report.png";
import smartReport from "../../Images/userDashboard_img/smartReport.png";
import manageAdd from "../../Images/userDashboard_img/manageAdd.png";
import help from "../../Images/userDashboard_img/help.png";
import axios from 'axios';
import Avatar from 'react-avatar';
import constantApi from '../../constantApi';

const UserProfile = () => {
    const navigate = useNavigate();
    const [userDetail, setUserDetail] = useState({
        countrycode: "",
        dob: "",
        email: "",
        name: "",
        phone: "",
        userId: ""
    });

    // Handle click on Edit Profile button
    const handleEdit = () => {
        navigate("/userform", { state: userDetail });
    };

    const handleManageAdd = () => {
        navigate("/ManageAddress", { state: userDetail });
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            axios
                .get(`${constantApi.baseUrl}user/`, {
                    headers: {
                        "Authorization": token
                    }
                })
                .then((response) => {
                    setUserDetail(response.data.data);
                })
                .catch((err) => {
                    console.log("error is ", err);
                });
        }
    }, []);

    return (
        <div className="container mx-auto p-4 px-4 md:px-20">
            <h1 className="text-2xl font-bold text-purple-500 mb-4 text-center">My Dashboard</h1>
            <hr className="border-t-2 border-purple-500 mx-auto w-1/5 mb-4" />
            <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                {/* User Profile Section */}
                <div className="bg-purple-100 shadow rounded-md p-4 col-span-1">
                    <div className="flex justify-center gap-8 items-center">
                        <div className="rounded-full p-8 font-bold text-sm bg-purple-900">
                            <Avatar name={userDetail.name} />
                        </div>
                        <div className="flex flex-col mb-2">
                            <div className="ml-4">
                                <h2 className="text-xl font-bold">{userDetail.name || "Name"}</h2>
                                <p className="text-gray-600">{userDetail.email || "Email"}</p>
                                <p className="text-gray-600">
                                    {userDetail.countrycode && userDetail.phone
                                        ? `${userDetail.countrycode} ${userDetail.phone}`
                                        : "Phone number"}
                                </p>
                            </div>
                            {userDetail.name && (
                                <div className="items-center flex justify-center mt-4">
                                    <button
                                        className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-md"
                                        onClick={handleEdit}
                                    >
                                        Edit Profile
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Other sections like "My Booking", "My Sample Tracking", etc. */}
                <div className="shadow rounded-md p-4 flex flex-col items-center justify-center hover:scale-105 cursor-pointer duration-500 bg-white">
                    <img src={booking} alt="booking" className="h-32 w-32" />
                    <h2 className="text-xl font-bold mt-2">My Booking</h2>
                </div>

                <div className="shadow rounded-md p-4 flex flex-col items-center justify-center hover:scale-105 cursor-pointer duration-500 bg-white">
                    <img src={report} alt="report" className="h-32 w-32" />
                    <h2 className="text-xl font-bold mt-2">My Reports</h2>
                </div>

                <div onClick={handleManageAdd}>
                    <div className="shadow rounded-md p-4 flex flex-col items-center justify-center hover:scale-105 cursor-pointer duration-500 bg-white">
                        <img src={manageAdd} alt="manageAdd" className="h-32 w-32" />
                        <h2 className="text-xl font-bold mt-2">Manage Address</h2>
                    </div>
                </div>

                <div className="shadow rounded-md p-4 flex flex-col items-center justify-center hover:scale-105 cursor-pointer duration-500 bg-white">
                    <img src={help} alt="help" className="h-32 w-32" />
                    <h2 className="text-xl font-bold mt-2">Help & Feedback</h2>
                </div>
            </div>
        </div>

    );
};

export default UserProfile;
