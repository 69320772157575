import React, { useState, useEffect } from 'react';
import mumbai from "../Images/location_img/Mumbai.svg";

const HeaderLocation = () => {
    // Popular city data
    const citiesData = [
        { city: 'Lucknow', image: mumbai },
        { city: 'Kanpur', image: mumbai },
        { city: 'Gorakhpur', image: mumbai },
        { city: 'Noida', image: mumbai },
        { city: 'Agra', image: mumbai },
    ];

    const [selectedCity, setSelectedCity] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const cities = [
        "Lucknow", "Kanpur", "Agra", "Varanasi", "Meerut", "Allahabad", "Ghaziabad",
        "Noida", "Bareilly", "Mathura", "Aligarh", "Jhansi", "Moradabad", "Firozabad",
        "Saharanpur", "Muzaffarnagar", "Rampur", "Etawah", "Bijnor", "Maharajganj",
        "Gorakhpur", "Sitapur", "Shahjahanpur", "Amethi", "Hapur",
        "New Delhi", "Dwarka", "Rohini", "Pitampura", "Janakpuri", "Karol Bagh",
        "Connaught Place", "Lajpat Nagar", "Saket", "Preet Vihar", "Vasant Kunj",
        "Greater Kailash", "Defence Colony", "Green Park", "Hauz Khas", "Shalimar Bagh",
        "Malviya Nagar", "Punjabi Bagh", "Civil Lines", "Chandni Chowk",
        "Mumbai", "Navi Mumbai", "Thane", "Andheri", "Borivali"
    ];
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleCitySelect = (city) => {
        setSelectedCity(city);
        closeModal();
    };
    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('modal-overlay')) {
            closeModal();
        }
    };

    // Lock body scroll when modal is open and unlock when it is closed
    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden'; // Disable background scroll
        } else {
            document.body.style.overflow = 'auto'; // Re-enable scroll
        }

        return () => {
            document.body.style.overflow = 'auto'; // Always ensure the scroll is unlocked on unmount
        };
    }, [isModalOpen]);

    const [add, setAdd] = useState('')


    useEffect(() => {
        navigator.geolocation.getCurrentPosition(pos => {
            const { latitude, longitude } = pos.coords;
            const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
            fetch(url).then(res => res.json()).then(data => setAdd(data.address))
        })
    }, [])

    return (
        <div className="flex-grow   relative ">
            <div className="w-full  relative bg-purple-300 px-2 rounded-s-lg">
                {/* Button Styled to Look Like Input */}
                <button
                    className="w-full  text-black cursor-pointer bg-transparent py-2"
                    onClick={openModal}
                >
                    <span className="text-gray-600">{add.city || "City"}</span>
                </button>
            </div>


            {/* Modal Popup */}
            {isModalOpen && (
                <div
                    className="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-40 pt-12 px-32"
                    onClick={handleOutsideClick}
                >
                    <div className="bg-white p-6 rounded-lg text-black w-full px-32">
                        <h2 className="text-xl mb-4 font-bold">Choose your City</h2>
                        <h2 className="text-xl mb-4">Most Popular</h2>

                        <div className='flex justify-between items-center'>
                            {citiesData.map((city, index) => (
                                <div key={index} className='flex flex-col items-center'>
                                    <div>
                                        <img src={city.image} alt={city.city} />
                                    </div>
                                    <div
                                        onClick={() => handleCitySelect(city.city)}
                                        className="py-2 px-4 hover:bg-gray-200 cursor-pointer"
                                    >
                                        {city.city}
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className='bg-gray-500 h-px w-full mt-8' />

                        <div className='mt-4 flex justify-between items-center'>
                            <h1 className='font-bold'>All Cities</h1>
                            <div className='my-4'>
                                <input placeholder='Search cities' className="w-full px-4 py-2 border border-gray-300 rounded-md" />
                            </div>
                        </div>
                        <div className='bg-gray-300 h-px w-full my-2' />


                        <div className='grid grid-cols-4 gap-2 max-h-20 overflow-y-auto'>
                            {cities.map((city, index) => (
                                <div key={index}>
                                    <p>{city}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HeaderLocation;
