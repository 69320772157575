import React, { useState, useEffect } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import constantApi from "../constantApi";
import { useCart } from "../context/CartContext";

const PopularTest = () => {
    const [tests, setTests] = useState([]);
    const navigate = useNavigate();
    const { addToCart, cartItemIds } = useCart();

    useEffect(() => {
        axios
            .get(`${constantApi.baseUrl}user/product/Popular Test`)
            .then((response) => {
                setTests(response.data.data);
            })
            .catch((error) => {
                console.error("Error fetching tests: ", error);
            });
    }, []);

    const handleView = (testId) => {
        const selectedTest = tests.find(test => test._id === testId);
        navigate(`/populartest/${testId}`, { state: { test: selectedTest } });
        // navigate(`/populartest1/${testId}`); // Redirect to test details page
    };

    return (
        <div className='px-4 sm:px-8'>
            <section className="mb-4">
                <div className="flex justify-between items-center mb-6">
                    <div>
                        <h2 className="lg:text-3xl md:text-xl sm:text-lg text-xl  font-bold mb-2 text-gray-800">Popular Test</h2>
                        <p className="lg:text-lg md:text-md sm:text-sm text-sm  font-semibold text-gray-800">Choose from our frequently booked blood tests</p>
                    </div>
                    <div>
                        <button
                            className="border border-[#522f7c] text-[#522f7c] py-1 px-4 text-sm rounded-md hover:bg-purple-700 hover:text-white transition duration-200 ease-in-out"
                            onClick={() => navigate('/populartestcollection')}
                        >
                            View More
                        </button>
                    </div>
                </div>

                <div className="relative mx-2">
                    {/* Left Scroll Button */}
                    <button
                        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-[#522f7c] text-white p-3 rounded-full shadow hover:bg-[#6a3fad] transition z-10 hidden sm:block"
                        onClick={() => {
                            document.getElementById('populartestcollection').scrollBy({ left: -360, behavior: 'smooth' });
                        }}
                    >
                        <FaAngleLeft />
                    </button>

                    {/* Right Scroll Button */}
                    <button
                        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-[#522f7c] text-white p-3 rounded-full shadow hover:bg-[#ca8fde] transition z-10 hidden sm:block"
                        onClick={() => {
                            document.getElementById('populartestcollection').scrollBy({ left: 360, behavior: 'smooth' });
                        }}
                    >
                        <FaAngleRight />
                    </button>

                    {/* Scrollable container */}
                    <div
                        id="populartestcollection"
                        className="flex overflow-x-auto space-x-8 py-4 scrollbar-hide"
                    >
                        {tests.map((test) => (
                            <div
                                key={test._id}
                                className="border-t-4 border-[#522f7c] p-6 shadow-lg rounded-lg hover:shadow-2xl transition duration-200 ease-in-out w-full cursor-pointer"
                                onClick={() => handleView(test._id)}
                            >
                                <div className="flex flex-col items-center w-72">
                                    <h3 className="text-lg font-bold text-gray-700">{test.name}</h3>
                                    <p>Rs.{test.amount}</p>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent navigation when clicking "Add to Cart"
                                            addToCart(test);
                                        }}
                                        className={`text-white py-1 px-4 w-4/5 h-10 text-sm rounded-md ${cartItemIds.includes(test._id) ? "bg-green-600" : "bg-[#522f7c]"}`}
                                    >
                                        {cartItemIds.includes(test._id) ? "Added to Cart" : "Add to Cart"}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default PopularTest;
