import React, { useState } from 'react';
import { IoIosSearch } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MdUploadFile } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { GiHamburgerMenu } from "react-icons/gi";
import Cookies from 'js-cookie';
import { faFileUpload, faTimes } from '@fortawesome/free-solid-svg-icons';
import SignInButton from '../pages/SignIn/SignInButton';
import Cart from '../pages/header/Cart';
import { useCart } from '../context/CartContext';
import { LuPackagePlus } from "react-icons/lu";
import { MdOutlineBloodtype } from "react-icons/md";
import { RiMentalHealthFill } from "react-icons/ri";
import { GiHeavyThornyTriskelion } from "react-icons/gi";
import { MdOutlineGppGood } from "react-icons/md";

const Header = () => {
  const navigate = useNavigate()
  const cartItem = useCart()
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [profile, setProfile] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleProfileHover = () => {
    setProfile(true);
  };
  const handleProfileLeave = () => {
    setProfile(false);
  };

  const handleLogout = () => {
    Cookies.remove('token')
    navigate("/")
  }
  const [isCartOpen, setIsCartOpen] = useState(false)

  const handleCart = () => {
    setIsCartOpen(!isCartOpen)
  }

  const handleHome = (() => {
    setIsProfileOpen(!isProfileOpen)
    console.log("hello Ashish");

  })
  const token = Cookies.get('token');

  return (
    <nav className="px-8 bg-white h-24 md:h-20 lg:h-16 shadow-md flex items-center sticky top-0 z-50">
      <div className="w-full flex justify-between items-center">

        {/* Hamburger Icon for mobile */}
        <div className="block lg:hidden flex justify-between items-center w-full">
          <div onClick={toggleMenu}>
            <GiHamburgerMenu className="text-black text-2xl cursor-pointer" />
          </div>

          {/* Brand Name */}
          <div className="font-bold text-lg flex-1 text-center">
            <Link to="/">Vallabhies Diagnosis</Link>
          </div>

          {/* Search Icon */}
          <div className='flex  items-center justify-between'>
            <div className="relative flex-grow max-w-md mx-4">
              <IoIosSearch className='text-2xl' />
            </div>

            {/* Cart Icon */}
            <div onClick={handleCart} className="relative">
              <i className="fas fa-shopping-cart text-xl"></i>
              <span className="absolute top-[-8px] right-[-10px] bg-[#522f7c] text-white rounded-full px-2 py-1 text-xs">
                {cartItem.cart.length || 0}
              </span>
            </div>
          </div>
        </div>

        {/* Desktop Navigation Menu */}
        <div className="hidden lg:flex gap-16 items-center justify-between text-base w-full">
          <div>
            <Link to="/healthrisknavbarlink" className="text-black">
              <button className="group text-md font-medium relative cursor-pointer bg-none border-none outline-none">
                Health Risks
                {/* Underline animation */}
                <span className="absolute left-0 bottom-[-6px] h-[1px] w-0 bg-[#522f7c] group-hover:w-full transition-all duration-300"></span>
              </button>
            </Link>
          </div>

          <div>
            <Link to="/health-conditions" className="text-black">
              <button className="group text-md font-medium relative cursor-pointer bg-none border-none outline-none">
                Health Conditions
                {/* Underline animation */}
                <span className="absolute left-0 bottom-[-6px] h-[1px] w-0 bg-[#522f7c] group-hover:w-full transition-all duration-300"></span>
              </button>
            </Link>
          </div>

          <div>
            <Link to="/bloodtest" className="text-black">
              <button className="group text-md font-medium relative cursor-pointer bg-none border-none outline-none">
                Blood Tests
                {/* Underline animation */}
                <span className="absolute left-0 bottom-[-6px] h-[1px] w-0 bg-[#522f7c] group-hover:w-full transition-all duration-300"></span>
              </button>
            </Link>
          </div>

          <div>
            <Link to="/healthpackagecollection" className="text-black">
              <button className="group text-md font-medium relative cursor-pointer bg-none border-none outline-none">
                Truehealth Packages
                {/* Underline animation */}
                <span className="absolute left-0 bottom-[-6px] h-[1px] w-0 bg-[#522f7c] group-hover:w-full transition-all duration-300"></span>
              </button>
            </Link>
          </div>

          <div>
            <Link to="/specialty" className="text-black">
              <button className="group text-md font-medium relative cursor-pointer bg-none border-none outline-none">
                Specialty
                {/* Underline animation */}
                <span className="absolute left-0 bottom-[-6px] h-[1px] w-0 bg-[#522f7c] group-hover:w-full transition-all duration-300"></span>
              </button>
            </Link>
          </div>

          <div>
            <Link to="/upload-report" className="text-black">
              <button className="group text-md font-medium relative cursor-pointer bg-none border-none outline-none">
                <FontAwesomeIcon icon={faFileUpload} /> Upload
                {/* Underline animation */}
                <span className="absolute left-0 bottom-[-6px] h-[1px] w-0 bg-[#522f7c] group-hover:w-full transition-all duration-300"></span>
              </button>
            </Link>
          </div>

          {/* Conditional rendering for logged-in users */}
          {token ? (
            <div
              onMouseEnter={handleProfileHover}
              onMouseLeave={handleProfileLeave}
              className="relative flex items-center"
            >
              <div>
                <CgProfile className="text-3xl cursor-pointer" />
              </div>
              {profile && (
                <div className="absolute top-7 left-1/2 transform -translate-x-1/2 bg-opacity-50 flex justify-center items-center z-50 transition-all ease-in-out duration-300">
                  <div className="bg-gray-100 p-2 rounded-lg w-52 relative shadow-lg">
                    <div className="space-y-0 mt-4">
                      <div>
                        <Link to="userprofile">
                          <h1 className="text-lg font-semibold">Dashboard</h1>
                        </Link>
                        <hr className="my-2 border-gray-400" />
                      </div>
                      <div>
                        <h2 className="text-lg font-semibold">My Sample Tracking</h2>
                        <hr className="my-2 border-gray-400" />
                      </div>
                      <div>
                        <h2 className="text-lg font-semibold">My Report</h2>
                        <hr className="my-2 border-gray-400" />
                      </div>
                      <div >
                        <button onClick={handleLogout} className="text-lg font-semibold">Logout</button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            // <div className='flex flex-row'>
            //   <SignInButton />
            // </div>
            <div className="lg:block hidden">
              <SignInButton />
            </div>
          )}

          <div className="flex items-center justify-between gap-8">
            <div onClick={handleCart} className="relative cursor-pointer">
              <i className="fas fa-shopping-cart text-2xl"></i>
              <span className="absolute top-[-8px] right-[-10px] bg-purple-500 text-black rounded-full px-2 py-1 text-xs">
                {cartItem.cart.length || 0}

              </span>
            </div>
          </div>
        </div>

        {/* Mobile Navigation Menu */}
        <div className={`lg:hidden fixed md:w-1/3 sm:w-2/3 w-2/3 inset-0 bg-white flex transition-transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'}`}>
          <div className="relative flex flex-col justify-start h-screen overflow-y-auto text-xl pe-8">
            <div className="absolute left-6 top-6">
              <FontAwesomeIcon icon={faTimes} className="text-black text-2xl cursor-pointer" onClick={toggleMenu} />
            </div>

            {/* Sample Menu Items */}
            <div className="flex flex-col gap-4  flex-grow mt-32 ps-6">
              <div>
                <div>
                  {token ? (
                    <div
                      onClick={handleHome}
                      className="relative flex items-center"
                    >
                      <div className='flex justify-center items-center'>
                        {/* <CgProfile className="text-3xl cursor-pointer  " /> */}
                        <label className="text-xl  text-center">Home</label>
                      </div>
                      {isProfileOpen && (
                        <div className="absolute top-7 left-1/2 transform -translate-x-1/2 bg-opacity-50 flex justify-center items-center z-50 transition-all ease-in-out duration-300">
                          <div className="bg-gray-100 p-2 rounded-lg w-52 relative shadow-lg">
                            <div className="space-y-0 mt-4">
                              <div>
                                <Link to="userprofile">
                                  <h1 className="text-lg font-semibold">Dashboard</h1>
                                </Link>
                                <hr className="my-2 border-gray-400" />
                              </div>
                              <div>
                                <h2 className="text-lg font-semibold">My Sample Tracking</h2>
                                <hr className="my-2 border-gray-400" />
                              </div>
                              <div>
                                <h2 className="text-lg font-semibold">My Report</h2>
                                <hr className="my-2 border-gray-400" />
                              </div>
                              <div >
                                <button onClick={handleLogout} className="text-lg font-semibold">Logout</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="lg:hidden block">
                      <SignInButton />
                    </div>
                  )}
                </div>
                <div className="w-full h-px bg-gray-300 mt-4"></div>
              </div>

              <div>
                <Link to="/healthrisknavbarlink" className="text-black flex items-center gap-2" onClick={toggleMenu}>
                  <GiHeavyThornyTriskelion />
                  <p>Health Risks</p>
                </Link>
                <div className="w-full h-px bg-gray-300 mt-4"></div>
              </div>

              {/* Additional links */}
              <div>
                <Link to="/health-conditions" className="text-black flex items-center gap-2" onClick={toggleMenu}>
                  <RiMentalHealthFill />
                  <p>Health Conditions</p>
                </Link>
                <div className="w-full h-px bg-gray-300 mt-4"></div>
              </div>

              <div>
                <Link to="/bloodTest" className="text-black flex items-center gap-2" onClick={toggleMenu}>
                  <MdOutlineBloodtype />
                  <p>Blood Tests</p>
                </Link>
                <div className="w-full h-px bg-gray-300 mt-4"></div>
              </div>

              <div>
                <Link to="/healthpackagecollection" className="text-black flex items-center gap-2" onClick={toggleMenu}>
                  <LuPackagePlus />
                  <p>Truehealth Packages</p>
                </Link>
                <div className="w-full h-px bg-gray-300 mt-4"></div>
              </div>
              <div>
                <Link to="/specialty" className="text-black flex items-center gap-2" onClick={toggleMenu}>
                  <MdOutlineGppGood />
                  <p>Specialty</p>
                </Link>
                <div className="w-full h-px bg-gray-300 mt-4"></div>
              </div>

              <div>
                <Link to="/upload-report" className="text-black flex items-center gap-2" onClick={toggleMenu}>
                  <MdUploadFile />
                  <p>Upload</p>
                </Link>
                <div className="w-full h-px bg-gray-300 mt-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Cart isCartOpen={isCartOpen} setIsCartOpen={setIsCartOpen} />
    </nav>
  );
};

export default Header;
