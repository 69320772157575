import React, { useState } from "react";
import { FaUpload, FaPrescriptionBottle } from "react-icons/fa";

const Upload = () => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    cityState: "",
    documents: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 3) {
      alert("You can only upload up to 3 documents.");
      return;
    }
    setFormData({ ...formData, documents: files });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.documents.length < 1) {
      alert("Please upload at least one document.");
      return;
    }
    console.log("Submitted Data:", formData);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-blue-50 to-gray-100">
      {/* Header Section */}
      <header className="text-center mb-8">
        <h1 className="text-4xl font-bold text-blue-600">Welcome to HealthEase</h1>
        <p className="text-gray-600 mt-2">
          Upload your prescription and let us help you with your medical tests.
        </p>
      </header>

      {/* Form Container */}
      <div className="max-w-lg w-full p-6 bg-white rounded-lg shadow-lg">
        <div className="flex items-center mb-6">
          <FaPrescriptionBottle className="text-green-600 text-3xl" />
          <h2 className="text-2xl font-bold ml-2">Upload Prescription</h2>
        </div>
        <p className="text-gray-600 mb-6">
          Not sure which tests to take? Share your prescription, and our team
          will guide you further.
        </p>

        {/* Form */}
        <form onSubmit={handleSubmit}>
          {/* Name */}
          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700 font-medium">
              Your Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              placeholder="Enter your full name"
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Mobile Number */}
          <div className="mb-4">
            <label
              htmlFor="mobile"
              className="block text-gray-700 font-medium"
            >
              Your Mobile Number
            </label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              required
              placeholder="Enter your mobile number"
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <p className="text-sm text-gray-500 mt-1">
              An OTP will be sent to this number
            </p>
          </div>

          {/* City/State */}
          <div className="mb-4">
            <label
              htmlFor="cityState"
              className="block text-gray-700 font-medium"
            >
              City and State
            </label>
            <input
              type="text"
              id="cityState"
              name="cityState"
              value={formData.cityState}
              onChange={handleInputChange}
              required
              placeholder="Enter your city and state"
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* File Upload */}
          <div className="mb-4">
            <label
              htmlFor="documents"
              className="block text-gray-700 font-medium"
            >
              Upload Prescription
            </label>
            <div className="flex items-center mt-2">
              <label
                htmlFor="documents"
                className="flex items-center px-4 py-2 bg-gray-100 border rounded-md cursor-pointer hover:bg-gray-200"
              >
                <FaUpload className="text-blue-500 mr-2" />
                <span>Upload Prescription</span>
              </label>
              <input
                type="file"
                id="documents"
                name="documents"
                accept=".pdf,.jpg,.png"
                onChange={handleFileChange}
                multiple
                className="hidden"
              />
            </div>
            <p className="mt-2 text-sm text-gray-500">
              You can upload up to 3 documents in PDF, JPG, or PNG format.
            </p>
            {formData.documents.length > 0 && (
              <p className="mt-2 text-sm text-green-600">
                {formData.documents.length} file(s) selected.
              </p>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Upload;
