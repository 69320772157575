import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay"; // Import the autoplay styles
import { Swiper, SwiperSlide } from "swiper/react";

import WhyChooseUs from "../HomePageContent/whyChooseus";
import GetCurrentAddress from "./GetCurrentAddress";
import banner1 from "../Images/home_img/banner1.png";
import banner2 from "../Images/home_img/banner2.png";
import banner3 from "../Images/home_img/banner3.png";
import HealthPackage from "../HomePageContent/HealthPackage";
import HealthRisk from "../HomePageContent/HealthRisk";
import HealthConditionTest from "../HomePageContent/HealthConditionTest";
import { Autoplay, Navigation } from "swiper/modules";
import PopularTest1 from "../HomePageContent/PopularTest";

export default function Home() {
  return (
    <div className="bg-gray-50">
      {/* Carousel Section */}
      <div className="relative w-full lg:py-8 md:py-4 sm:py-2 py-2 h-full">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          // navigation={true}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[Navigation, Autoplay]}
        >
          {/* Slide 1 */}
          <SwiperSlide>
            <img
              src={banner1}
              className="w-full h-full object-cover"
              alt="Slide 1"
            />
          </SwiperSlide>

          {/* Slide 2 */}
          <SwiperSlide>
            <img
              src={banner2}
              className="w-full h-full object-cover"
              alt="Slide 2"
            />
          </SwiperSlide>

          {/* Slide 3 */}
          <SwiperSlide>
            <img
              src={banner3}
              className="w-full h-full object-cover"
              alt="Slide 3"
            />
          </SwiperSlide>
        </Swiper>
      </div>

      {/* Other Components */}
      <HealthPackage />
      <PopularTest1 />
      <HealthRisk />
      <HealthConditionTest />
      <WhyChooseUs />
      <GetCurrentAddress />
    </div>
  );
}
